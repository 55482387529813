import {Paths} from '@halp/foundation/Paths';
import {Dropdown, Link} from '@halp/ui';
import {capitalize} from '@halp/util';
import type {CoachingType} from './types';

interface Props {
	coachId?: string | null;
	coachingType: CoachingType;
	setCoachingType?: (type: CoachingType) => void;
}

export function CoachingTypeDropdown({
	coachId,
	coachingType,
	setCoachingType,
}: Props) {
	return (
		<Dropdown toggle={capitalize(coachingType)} caret closeOnOutsideClick>
			<ul>
				<li>
					<Link
						href={
							coachId
								? Paths.users.coaching.domestic.all.url(coachId, 'admin')
								: Paths.admin.coaching.index.url('domestic')
						}
						onClick={() => setCoachingType?.('domestic')}
					>
						Domestic
					</Link>
				</li>
				<li>
					<Link
						href={
							coachId
								? Paths.users.coaching.international.all.url(coachId, 'admin')
								: Paths.admin.coaching.index.url('international')
						}
						onClick={() => setCoachingType?.('international')}
					>
						International
					</Link>
				</li>
			</ul>
		</Dropdown>
	);
}
