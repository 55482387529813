import {
	DOMESTIC_STUDENT_STATUSES_SLUG,
	STUDENT_STATUSES_SLUG,
} from '@halp/util/constants';
import type {CoachingType} from './CoachingDropdown/types';
import type {
	InternationalCoachingFunnelStage,
	DomesticCoachingFunnelStage,
} from '@halp/foundation/Paths/user';

export function getCoachingTypeFromSlug(slug: string | string[] | undefined) {
	if (!Array.isArray(slug) && slug === 'coaching') {
		return 'international';
	}

	return 'domestic';
}

export function buildPrefix(
	coachingType: CoachingType,
	funnelStage: string = '',
	coachId?: string,
) {
	if (coachingType === 'international') {
		if (funnelStage in STUDENT_STATUSES_SLUG) {
			return `/coaching/${coachId}/${funnelStage as InternationalCoachingFunnelStage}` as const;
		}

		return `/coaching/${coachId}/all` as const;
	}

	if (funnelStage in DOMESTIC_STUDENT_STATUSES_SLUG) {
		return `/coaching-domestic/${coachId}/${funnelStage as DomesticCoachingFunnelStage}` as const;
	}

	return `/coaching-domestic/${coachId}/all` as const;
}
