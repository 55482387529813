import {Dropdown, Link} from '@halp/ui';
import {Paths} from '@halp/foundation/Paths';
import type {CoachingType} from './types';

interface Props {
	coachId?: string | null;
	coaches: readonly {
		user: {
			name: string | null;
		};
		id: string;
	}[];
	coachingType: CoachingType;
}

export function CoachDropdown({coachId, coaches, coachingType}: Props) {
	const coachList = coaches.map((coach) => {
		return (
			<li key={coach.id}>
				<Link
					href={
						coachingType === 'domestic'
							? Paths.users.coaching.domestic.all.url(coach.id, 'admin')
							: Paths.users.coaching.international.all.url(coach.id, 'admin')
					}
				>
					{coach.user.name}
				</Link>
			</li>
		);
	});

	const coachName = coaches.find((coach) => coach.id === coachId)?.user.name;
	const dropdownTitle = !coachId ? 'Select a Coach' : coachName;

	return (
		<Dropdown toggle={dropdownTitle} caret closeOnOutsideClick>
			<ul>{coachList}</ul>
		</Dropdown>
	);
}
