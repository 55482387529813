import {Stack} from '@halp/ui';
import {CoachDropdown} from './CoachDropdown';
import {CoachingTypeDropdown} from './CoachingTypeDropdown';
import type {CoachingType} from './types';

interface Props {
	coachId?: string | null;
	coaches: readonly {
		user: {
			name: string | null;
		};
		id: string;
	}[];
	coachingType: CoachingType;
	setCoachingType?: (type: CoachingType) => void;
}

export function CoachingDropdown({
	coachId,
	coaches,
	coachingType,
	setCoachingType,
}: Props) {
	return (
		<Stack direction="row" justifyContent="flex-start" spacing="md">
			<CoachDropdown
				coachId={coachId}
				coachingType={coachingType}
				coaches={coaches}
			/>
			<CoachingTypeDropdown
				coachId={coachId}
				coachingType={coachingType}
				setCoachingType={setCoachingType}
			/>
		</Stack>
	);
}
